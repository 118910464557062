import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/WhereToBuy.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from './StyledLink';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { navigateTo } from 'gatsby-link';

import { breakpoints, colors } from '../style-utilities/variables';

const Button = styled.button`
  grid-area: WhereToBuy;
  padding: 12px 30px;
  background: ${colors.primary};
  color: white;
  cursor: pointer;
  border: none;
  display: block;
  margin: auto;
  :hover {
    opacity: 0.8;
  }
  .wtb-anchor-link a,
  .wtb-anchor-link a:hover,
  .wtb-anchor-link a:active,
  .wtb-anchor-link a:visited,
  .wtb-anchor-link a:focus {
    text-decoration: none;
    color: white;
  }
  .LocationIcon {
    font-size: 20px;
    margin: 0 7px 0 0;
    color: white;
    transform-origin: 0.375em 0.5em;
    width: 16px;
    height: 16px;
  }

  @media (max-width: ${breakpoints.desktop}) {
    display: block;
    margin: auto;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class WhereToBuy extends Component {
  render() {
    return (
      <>
        {this.props.siteTitle === 'minute' ||
        this.props.siteTitle === 'success' ||
        this.props.siteTitle === 'carolina' ? (
          <Button
            className="wtb wtb-anchor-link"
            onClick={() => navigateTo('/where-to-buy')}
          >
            <FontAwesomeIcon className="LocationIcon" icon={faMapMarkerAlt} />
            Find Now
          </Button>
        ) : (
          // <a href="/where-to-buy" className="wtb-anchor-link">
          <Button
            // {...remainProps}
            className="wtb wtb-anchor-link"
            ps-sku={this.props.skuNum}
            onClick={e => {
              if (
                document.querySelector('div.ps-widget.ps-enabled') &&
                this.props.skuNum
              ) {
                e.preventDefault();
                document.querySelector('div.ps-widget.ps-enabled').click();
              } else {
                navigateTo('/where-to-buy');
              }
            }}
          >
            <FontAwesomeIcon className="LocationIcon" icon={faMapMarkerAlt} />
            Find Now
          </Button>
          // </a>
        )}
      </>
    );
  }
}

export default WhereToBuy;
