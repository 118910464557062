import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ComponentStyles from '../brand-styles/pages/recipe-collection-detail.styles';

import ShareWidget from '../components/ShareWidget';
import PagesHero from '../components/PagesHero';
import Heading from '../components/Heading';
import SubHeading from '../components/SubHeading';
import RegularButton from '../components/RegularButton';
import RecipeSearchWidget from '../components/RecipeSearchWidget';
import RecipeCard from '../components/RecipeCard';
import placeHolderImage from '../images/placeholder.png';
import { breakpoints } from '../style-utilities/variables';
import WhereToBuy from '../components/WhereToBuy';

const TopSection = styled.div`
  background: #efeff0;
  .where-to-buy {
    display: none;
  }
  .top-button {
    padding: 25px 40px 40px;
    #button {
      background: transparent;
      border: none;
    }
  }
  .hero {
    background: transparent;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .hero-image {
    }
    .hero-text {
      padding: 2rem 5rem;
      margin: 2rem;
    }
  }
  .widget-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: white;
    padding: 20px;
    border: 1px solid gray;
    border-left: none;
    border-right: none;
    .widget {
      padding-left: 50%;
    }
    #favorite {
      display: flex;
      align-items: center;
    }
  }
`;

const MidSection = styled.div`
  text-align: center;
  padding: 40px;
  .recipe-card {
    margin: 20px;
  }
  .display-recipes {
    text-align: center;
  }
  .see-all-button {
    margin: auto;
  }
`;

const Div = styled.div`
  @media (max-width: ${breakpoints.tablet}) {
    ${TopSection} {
      .where-to-buy {
        display: block;
        text-align: center;
      }
      .top-button {
        display: none;
      }
      .hero {
        display: block;
      }
      .hero-image {
        min-height: 180px;
        margin: 10px;
        border: 1px solid gray;
      }
      .hero-text {
        padding: 10px;
        margin: 0;
      }
      .widget-section {
        display: block;
        text-align: center;
        .widget {
          padding-left: 0;
        }
        #favorite {
          margin: 10px;
          display: block;
        }
      }
    }
  }
  ${TopSection}
  ${MidSection}
  ${ComponentStyles}
`;

const recipes = [
  {
    imageUrl: placeHolderImage,
    id: 'Recipe 1',
    title: 'Recipe 1',
    cuisine: 'Mexican',
    mainIngredient: 'beef',
    cookTime: '23 minutes',
    meal: 'breakfast'
  },
  {
    imageUrl: placeHolderImage,
    id: 'Recipe 2',
    title: 'Recipe 2',
    cuisine: 'American',
    mainIngredient: 'poultry',
    cookTime: '30 minutes',
    meal: 'dinner'
  },
  {
    imageUrl: placeHolderImage,
    id: 'Recipe 3',
    title: 'Recipe 3',
    cuisine: 'Asian',
    mainIngredient: 'pork',
    cookTime: '13 minutes',
    meal: 'appetizer'
  },
  {
    imageUrl: placeHolderImage,
    id: 'Recipe 4',
    title: 'Recipe 4',
    cuisine: 'Italian',
    mainIngredient: 'chicken',
    cookTime: '27 minutes',
    meal: 'dinner'
  },
  {
    imageUrl: placeHolderImage,
    id: 'Recipe 5',
    title: 'Recipe 5',
    cuisine: 'Mexican',
    mainIngredient: 'beef',
    cookTime: '16 minutes',
    meal: 'breakfast'
  },
  {
    imageUrl: placeHolderImage,
    id: 'Recipe 6',
    title: 'Recipe 6',
    cuisine: 'American',
    mainIngredient: 'vegan',
    cookTime: '19 minutes',
    meal: 'lunch'
  }
];

class RecipeCollectionDetail extends Component {
  /**
   * METHOD: Render recipes from the collection
   */
  renderRecipes = () => {
    return recipes.map(recipe => {
      return (
        <RecipeCard
          className="recipe-card"
          id={recipe.id}
          key={recipe.id}
          title={recipe.title}
          imageUrl={recipe.imageUrl}
          mainIngredient={recipe.mainIngredient}
          cuisine={recipe.cuisine}
          meal={recipe.meal}
          cookTime={recipe.cookTime}
        />
      );
    });
  };

  render() {
    return (
      <Div>
        <TopSection>
          <div className="top-button">
            <FontAwesomeIcon id="AllRecipeIcon" icon={faEnvelope} />
            <button id="button">See all Collections</button>
          </div>
          <PagesHero className="hero">
            <div className="hero-image" />
            <div className="hero-text">
              <SubHeading>Collections</SubHeading>
              <Heading>Top 10 Summer BBQ</Heading>
              <p>
                Lorem ipsum dolor sit amet, conse adipiscing elit. Aenean
                euismod bibendum laoreet. Lorem ipsum dolor sit amet, tetur
                adipiscing elit. sit amet, tetur adipiscing elit.
              </p>
            </div>
          </PagesHero>
          <div className="widget-section">
            <ShareWidget className="widget" />
            <div id="favorite">Save all 10 Recipes as Favorites</div>
          </div>
        </TopSection>
        <MidSection>
          <Heading>Header for Recipes</Heading>
          <SubHeading>{this.renderRecipes().length} Recipes</SubHeading>
          <div className="display-recipes">{this.renderRecipes()}</div>
          <RegularButton className="seel-all-button">
            See all Collections
          </RegularButton>
        </MidSection>
        <RecipeSearchWidget className="search-widget" />
      </Div>
    );
  }
}

export default RecipeCollectionDetail;
